<template>
  <div>
    <topbar :title="fenDianZiLiao"></topbar>
    <div class="detailBox">
      <p class="name">{{ shopDetail.storeName }}</p>
      <div class="tag">
        <div v-for="(item, index) in shopDetail.businessType" :key="index">
          <p
            :class="{
              TS: item == 3,
              ZQ: item == 2,
            }"
          >
            <i></i>{{ shopDetail.businessTypeName[item] }}
          </p>
        </div>
      </div>
      <p class="time">
        {{ businessHours }}：<span
          >{{
            shopDetail.businessTimes ? shopDetail.businessTimes[0].beginTime : 0
          }}
          -
          {{
            shopDetail.businessTimes ? shopDetail.businessTimes[0].endTime : 0
          }}</span
        >
        <!-- (<span>星期一至日</span> -->
      </p>
      <p class="phone">
        {{ tel }}：<span>{{
          shopDetail.phoneNumberList ? shopDetail.phoneNumberList[0] : ""
        }}</span>
      </p>
      <!-- <p class="title">{{ address }}</p> -->
      <p class="address">
        {{ address }}：<span>{{ shopDetail.storeAddress }}</span>
      </p>
      <div id="map">
        <GoogleMap
          api-key="AIzaSyAsvvcMhcg_aVl8llTs-xJwwhVujT9yKLo"
          style="width: 3.42rem; height: 100%"
          :center="shopDetail.center"
          :zoom="16"
          :disableDefaultUI="true"
          :draggable="false"
        >
          <Marker
            :options="{
              position: shopDetail.center,
              icon: markerOptions,
            }"
          />
        </GoogleMap>
      </div>
    </div>
    <div class="btn" @click="goOrder">{{ startOrder }}</div>
    <confirm-box
      @finish="closeBox"
      v-show="showBox"
      :title="title"
      :content="content"
      :show="showBtn"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import { postDC } from "../../assets/utils/request";
import { GoogleMap, Marker } from "vue3-google-map";
import language from "../../assets/js/language.js";

export default {
  name: "ShopDetail",
  data() {
    return {
      shopId: 0,
      shopDetail: {},
      resStatus: 0, //返回的状态吗
      resMsg: "", //返回的错误信息
      lanType: this.$store.state.language,
      showBox: false,
      content: "",
      title: "",
      markerOptions: {
        url: require("../../assets/icon/mapLogo.png"),
        size: { width: 40, height: 40, f: "px", b: "px" },
        scaledSize: { width: 40, height: 40, f: "px", b: "px" },
      },
      beginTime: "",
      endTime: "",
      fenDianZiLiao: "",
      businessHours: "",
      tel: "",
      address: "",
      startOrder: "",
      HQXXSB: "",
    };
  },
  methods: {
    async getShopDetail() {
      var params = {
        actionName: "candao.storeStandard.getStore",
        langType: this.$store.state.language,
        content: {
          storeId: this.shopId,
          businessType: 1,
        },
      };
      let result = await postDC("Action", params);
      this.resStatus = result.status;
      if (result.status == 1) {
        result.data.businessType = result.data.businessType.splice(0, 3);
        for (var index = 0; index < result.data.businessType.length; index++) {
          if (result.data.businessType[index] == 1) {
            result.data.businessTypeName[result.data.businessType[index]] =
              this.$store.state.language == 1 ? "外賣速遞" : "Delivery";
          } else if (result.data.businessType[index] == 2) {
            result.data.businessTypeName[result.data.businessType[index]] =
              this.$store.state.language == 1 ? "自取" : "Self Pick-up";
          } else if (
            result.data.businessType[index] == 3 ||
            result.data.businessType[index] == 4
          ) {
            result.data.businessTypeName[result.data.businessType[index]] =
              this.$store.state.language == 1 ? "堂食" : "Dine-in";
          }
        }
        delete result.data.businessTypeName[4];
        this.shopDetail = result.data;
        this.shopDetail.center = {
          lat: this.shopDetail.coordinate[1],
          lng: this.shopDetail.coordinate[0],
        };
        this.beginTime = result.data.businessTimes.beginTime;
        this.endTime = result.data.businessTimes.endTime;
      } else {
        this.resMsg = this.HQXXSB;
        this.$toast(this.HQXXSB);
      }
    },
    goOrder() {
      if (this.resStatus != 1) {
        return this.$toast(this.resMsg);
      }
      var week = new Date().getDay();
      if (week == 0) {
        week = 7;
      }
      if (
        this.shopDetail.businessTimePros &&
        this.shopDetail.businessTimePros.length
      ) {
        if (this.shopDetail.businessTimePros[0].days.indexOf(week) < 0) {
          this.showBox = !this.showBox;
          return;
        }
      }
      if (
        this.shopDetail.businessTimes &&
        this.shopDetail.businessTimes.length
      ) {
        var nowTime = new Date().getTime();
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        var day = new Date().getDate();
        var date = year + "-" + month + "-" + day;
        var startTime = new Date(
          date + " " + this.shopDetail.businessTimes[0].beginTime
        ).getTime();
        var endTime = new Date(
          date + " " + this.shopDetail.businessTimes[0].endTime
        ).getTime();
        if (nowTime < startTime || nowTime > endTime) {
          this.showBox = !this.showBox;
          return;
        }
      }
      this.shopDetail.businessType = 3;
      var info = this.shopDetail;
      info = JSON.stringify(info);
      info = encodeURIComponent(info);
      this.$router.push("/orderFood/" + info);
    },
    closeBox() {
      this.showBox = !this.showBox;
    },
    setLanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.fenDianZiLiao = language.fenDianZiLiao[type];
      this.businessHours = language.businessHours[type];
      this.tel = language.tel[type];
      this.address = language.address[type];
      this.startOrder = language.startOrder[type];
      this.HQXXSB = language.HQXXSB[type];
      this.content = language.DQMDBZYYSJN[type];
      this.title = language.QXZQTMD[type];
    },
  },
  created() {
    this.setLanguage();
    this.shopId = this.$route.query.id;
    this.getShopDetail();
  },
  components: { Topbar, GoogleMap, Marker, ConfirmBox },
};
</script>
<style scoped>
.detailBox {
  width: 100%;
  box-sizing: border-box;
  padding: 0.16rem;
}
.name {
  height: 0.28rem;
  font-size: 0.2rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.28rem;
  color: #030f14;
  text-align: left;
}
.tag {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.04rem 0 0.16rem;
}
.tag p {
  color: #03060d;
  font-size: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.02rem 0.06rem;
  border-radius: 0.04rem;
  background-color: #f2f3f5;
}
.tag p i {
  width: 0.13rem;
  height: 0.1rem;
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.tag p.TS {
  background-color: #f5e9eb;
}
.tag p.TS i {
  width: 0.16rem;
  height: 0.09rem;
  background: url("../../assets/icon/ts.png") no-repeat center;
  background-size: cover;
}
.tag p.ZQ {
  background-color: #ff7c9b;
}
.tag p.ZQ i {
  width: 0.12rem;
  height: 0.09rem;
  background: url("../../assets/icon/ziqu.png") no-repeat center;
  background-size: cover;
}
.tag div + div {
  margin-left: 0.04rem;
}
.time,
.phone,
.address {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #030f14;
  text-align: left;
  margin-bottom: 0.08rem;
}
.title {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.22rem;
  color: #030f14;
  text-align: left;
  margin-top: 0.2rem;
  margin-bottom: 0.12rem;
}
#map {
  width: 100%;
  height: 1rem;
  border-radius: 0.12rem;
  background: url("../../assets/icon/map1.png") no-repeat center;
  background-size: cover;
}
#map img {
  width: 100%;
  height: 100%;
}
.btn {
  width: 3.4rem;
  height: 0.44rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
  background-color: #e4022b;
  border-radius: 0.08rem;
  position: fixed;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>